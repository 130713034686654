// import { useState, useEffect } from 'react';
//stylesheet
import styles from '../../styles/form.module.css';

//ui components
import FormInput from '../../layouts/FormInput';
import TextAreaInput from '../../layouts/TextArea';
import ScrollToTop from '../../components/ScrollToTop';

const CreateMovementPageTwo = (props) => {
  let two = JSON.parse(sessionStorage.getItem('MSTwo'));
  return (
    <>
      <ScrollToTop />

      <h2 className={styles.subtitle1}>Name your movement</h2>
      <div className={styles.inputDivider}>
        <FormInput
          label={'Movement Name'}
          jsonData={'movementName'}
          register={props.register}
          // value={values.movementName}
          required
        />
      </div>
      <h2 className={styles.subtitle1}>
        Give us a brief description of the movement cause.
      </h2>
      <TextAreaInput
        // register={props.register}
        register={props.register}
      />
    </>
  );
};

export default CreateMovementPageTwo;
