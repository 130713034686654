import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import NavBar from '../Navbar';
import ScrollToTop from '../../components/ScrollToTop';
import FormInput from '../../layouts/FormInput';
import SelectUI from '../../layouts/Select';

import { tips } from '../../constants/tips';
import { UserContext } from '../../components/UserContext';
import { getMovementInfo } from '../../config/FirbaseFunctions';
import { FaAngleLeft } from 'react-icons/fa';

//styles
import styles from '../../styles/donations.module.css';

const fixedDonationAmounts = [
  { amount: 5, value: 500, label: '$5' },
  { amount: 10, value: 1000, label: '$10' },
  { amount: 20, value: 2000, label: '$20' },
  { amount: 50, value: 5000, label: '$50' },
  { amount: 100, value: 10000, label: '$100' },
  { amount: 200, value: 20000, label: '$200' },
  { amount: 500, value: 50000, label: '$500' },
  { amount: 1000, value: 100000, label: '$1000' },
];

const DonationOptions = (props) => {
  const [displayedAmount, setDisplayedAmount] = useState('');
  const [donationValue, setDonationValue] = useState('');
  const [details, setDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const movementId = params.id;

  console.log(movementId);

  const fetchMovementInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getMovementInfo(movementId);
      setDetails(data);
      console.log(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  // console.log(location);
  const goBack = () => {
    navigate(-1);
  };
  const handlePillClick = (amount, value) => {
    setDisplayedAmount(amount);
    setDonationValue(value);
    setValue('money', value);
  };

  const context = useContext(UserContext);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (details && details.formSubmission) {
      const charityName = details.formSubmission.charity;

      var submission = {
        ...data,
        money: donationValue,
        movementId,
        charityName,
        yourDonation: donationValue
      };
      var real_amount = submission.money;
      var tip_percentage = parseFloat(submission.tipAmount);
      //adding tip
      real_amount =real_amount + real_amount * (tip_percentage/100);
      //adding fee
      //console.log("REAL AMOUNT BEFORE FEE",real_amount);
      if(submission.fee == 'true')
      {
      //  console.log("FOUND THE FEE");
        real_amount = real_amount + (real_amount * 0.03);
      }
     //// console.log("new amount with tip + fee", real_amount);
      submission['money'] = real_amount;
//console.log("ONSUBMIT",submission)
      // Serialize and store the object in local storage
      try {
        const serializedSubmission = JSON.stringify(submission);
        localStorage.setItem('donationSubmission', serializedSubmission);
        console.log('Submission stored in local storage');

        navigate(`/make/payment/${movementId}`);
      } catch (error) {
        console.error('Error storing submission in local storage:', error);
      }
    }
  };

  console.log(errors);

  useEffect(() => {
    fetchMovementInfo();
  }, []);

  return (
    <>
      <NavBar />

      <div className={styles.container}>
        {/* Conditional rendering to check if details is not null */}

        <form onSubmit={handleSubmit(onSubmit)} className={styles.donationForm}>
          <ScrollToTop />
          {details ? (
            <>
              <div className={styles.back_btn_container}>
                <FaAngleLeft
                  className={styles.back_btn}
                  onClick={goBack}
                  size={35}
                />

                <p className={styles.title}>
                  {details.formSubmission?.movementName}
                </p>
              </div>
              <div className={styles.user}>
                <img className={styles.photo} src={context.photoUrl} alt='' />
                <p className={styles.userName}>
                  <span className={styles.charityName}>
                    {details.formSubmission?.charity}
                  </span>
                  {context.displayName}
                </p>
              </div>
              {/* Rest of your form */}
            </>
          ) : (
            <p>Loading...</p> // You can replace this with a proper loading indicator
          )}

          <h2 className={styles.subtitle1}>
            How much would you like to donate ?
          </h2>

          <span className={styles.tip_subtitle}>
            Select or type a custom amount you would like to donate.
          </span>

          <div className={styles.donationPills}>
            {fixedDonationAmounts.map(({ amount, value, label }) => (
              <button
                key={value}
                className={styles.donationPill}
                onClick={() => handlePillClick(amount, value)}
              >
                {label}
              </button>
            ))}
          </div>
          <div className={styles.inputDivider}>
            <FormInput
              jsonData={'money'}
              label={'$ Donation Amount'}
              type='number'
              min={1}
              step={0.01}
              register={register}
              required
              value={displayedAmount}
              onChange={(e) => {
                setDisplayedAmount(e.target.value);
                setDonationValue(e.target.value * 100);
              }}
            />
          </div>
          <div className={styles.tip_text}>
            <span className={styles.tip_title}>
              Tip Move For Change services
            </span>

            <span className={styles.tip_subtitle}>
              Move For Change has a 0% platform fee for organizers and relies on
              the generosity of donors like you to operate our service.
            </span>
          </div>
          <SelectUI
            json={tips}
            label={`Select Tip Amount`}
            {...register('tipAmount')}
            required
          />
          <div>
            <br />
            <label>
              Would you like to help cover the transaction fee of 3%
            </label>

            <div className={styles.toggle_options}>
              <input
                className={styles.toggle_input}
                id='option-yes'
                {...register('fee')}
                type='radio'
                value='true'
                required
              />
              <label className={styles.radio_label} htmlFor='option-yes'>
                <span className={styles.radio_button}></span>
                Yes
              </label>

              <input
                className={styles.toggle_input}
                id='option-no'
                {...register('fee')}
                type='radio'
                value='false'
                required
              />
              <label className={styles.radio_label} htmlFor='option-no'>
                <span className={styles.radio_button}></span>
                No
              </label>
            </div>
          </div>
          <br />

          <div className={styles.btn}>
            <button className={styles.continue_btn} type='submit'>
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DonationOptions;
