import CreateScreen from '../CreateScreen';
import CreateMovementPageOne from '../page/CreateMovementPageOne';

const MSOne = () => {
  return (
    <>
      <CreateScreen
        back
        // step
        // stepCount='1 of 3'
        stepTitleMain="Let's begin your movement journey"
        stepTitleSub="We're here to guide you every step of the way."
        formTitle='Getting Started'
        component={CreateMovementPageOne}
        keyVal={'MSOne'}
        page={'/create/movement/new'}
      />
    </>
  );
};
export default MSOne;
