import {
  addDoc,
  setDoc,
  updateDoc,
  collection,
  Timestamp,
  doc,
  getDocs,
  getDoc,
  getFirestore,
  query,
  where,
  deleteDoc,
} from '@firebase/firestore';
import { firestore } from '../config/firebaseConfig';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const createPaymentIntentOnFirebase = async (data) => {
  const functions = getFunctions();
  const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
  try {
    console.log(data);
    const response = await createPaymentIntent(data);
    console.log("from firebase call:",response.data);
    return response.data; // This contains the clientSecret
  } catch (error) {
    console.error('Error creating payment intent:', error);
  }
};

export const fetchPaymentIntentDetails = async (paymentIntentId) => {
  const functions = getFunctions();
  const getPaymentIntentDetails = httpsCallable(
    functions,
    'getPaymentIntentDetails'
  );
  try {
    const result = await getPaymentIntentDetails({ paymentIntentId });
    return result.data;
  } catch (error) {
    console.error('Error fetching PaymentIntent details:', error);
    throw error;
  }
};

export const stripeCheckout = async (data) => {
  const functions = getFunctions();
  const stripePub = await loadStripe(
    'pk_live_51H2UBNIZGnx7Z30tjth4XgtYpYN1R8C6HCRhrpBfInuc88uJD0dGB5LqOEHWpvrFvGe6IAmMj3dFwniIU64mkREc004qrVRVvB'
  );
  const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout');
  try {
    const response = await createStripeCheckout(data);
    const sessionId = response.data.id;
    const result = await stripePub.redirectToCheckout({ sessionId: sessionId });
    if (result.data.success) {
      return result;
    } else {
      if (result.error) {
        // Handle error during checkout redirection
        console.error('console error: ', result.error);
      }
      throw new Error('throw error: ', result.message); // Throw an error if the result is not successful
    }
  } catch (error) {
    // Handle error from the Firebase callable function or during the checkout process
    console.error('catch error: ', error);
  }
};

export const stripeCheckout2 = async (data, cardElement) => {
  const functions = getFunctions();
  const stripePub = await loadStripe(
    'pk_live_51H2UBNIZGnx7Z30tjth4XgtYpYN1R8C6HCRhrpBfInuc88uJD0dGB5LqOEHWpvrFvGe6IAmMj3dFwniIU64mkREc004qrVRVvB'
  );
  const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout');

  try {
    // Create Stripe Checkout session
    const sessionResponse = await createStripeCheckout(data);
    const sessionId = sessionResponse.data.id;

    if (!stripePub || !cardElement) {
      throw new Error('Stripe has not been properly initialized');
    }

    // Confirm card payment
    const result = await stripePub.confirmCardPayment(sessionId, {
      payment_method: {
        card: cardElement,
        billing_details: {
          // Add billing details if required
        },
      },
    });

    if (result.error) {
      console.error('Error during payment confirmation:', result.error);
      throw new Error('Payment confirmation error');
    }

    if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
      // Handle successful payment here
      console.log('Payment successful:', result.paymentIntent);
      return result.paymentIntent;
    }

    throw new Error('Payment did not succeed');
  } catch (error) {
    console.error('Error in stripeCheckout function:', error);
    throw error;
  }
};

export const createMovement = async (object) => {
  const formSubmission = object.formSubmission;

  const validateData = (data) => {
    const errors = {};

    // Check required fields
    const requiredFields = [
      'amtPerKm',
      'charity',
      'country',
      'money',
      'movementName',
      'movementType',
      'startDate',
      'endDate',
    ];
    requiredFields.forEach((field) => {
      if (!data[field] || data[field].trim() === '') {
        errors[field] = 'This field is required.';
      }
    });

    // Validate numeric fields
    const numericFields = ['amtPerKm', 'money'];
    numericFields.forEach((field) => {
      const value = parseFloat(data[field]);
      if (isNaN(value) || value <= 0) {
        errors[field] = 'Invalid value.';
      }
    });

    // Validate string fields
    const stringFields = ['charity', 'country', 'movementName'];
    stringFields.forEach((field) => {
      if (data[field] && data[field].length > 100) {
        errors[field] = 'Exceeded character limit.';
      }
    });

    // Validate date fields
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    if (
      isNaN(startDate.getTime()) ||
      isNaN(endDate.getTime()) ||
      endDate < startDate
    ) {
      errors.startDate = 'Invalid start or end date.';
    }

    // Additional business logic validations
    // Add any custom validations based on your specific requirements

    return errors;
  };

  try {
    const errors = await validateData(formSubmission);
    if (Object.keys(errors).length > 0) {
      console.log('Validation errors: ' + JSON.stringify(errors));
      return JSON.stringify(errors);
    } else {
      const functions = getFunctions();
      const create = httpsCallable(functions, 'createMovement');
      const result = await create(object);
      console.log(result);
      if (result.data.success) {
        return result;
      } else {
        throw new Error(result.message); // Throw an error if the result is not successful
      }
    }
  } catch (error) {
    console.error('Error adding document: ', error);
    throw error; // Re-throw the error to propagate it to the caller
  }
};

export const getCurrentDate = () => {
  const date = new Date();
  const timestamp = Timestamp.fromDate(date);
  // const finalDate = new Date(timestamp.seconds * 1000).toLocaleDateString();
  const finalDate = timestamp.seconds;
  console.log(timestamp.seconds);
  return finalDate;
};

export const getuCurrentUserID = async () => {
  return {};
};

export const getAllMovements = async () => {
  const db = firestore;
  const collectionRef = collection(db, 'MFC_Movement');
  let movements = [];
  try {
    const docsSnap = await getDocs(collectionRef);
    docsSnap.forEach((doc) => {
      movements.push({
        id: doc.id,
        data: doc.data(),
      });
    });
    return movements;
  } catch (err) {
    console.log(err);
  }
  // console.log(movements);
  // console.log('here');
};

export const getMovementsByUid = async (uid) => {
  const db = firestore;
  const collectionRef = collection(db, 'MFC_Movement');

  const q = query(collectionRef, where('userID', '==', uid));

  try {
    const querySnapshot = await getDocs(q);
    const movements = [];
    querySnapshot.forEach((doc) => {
      movements.push({
        id: doc.id,
        data: doc.data(),
      });
    });
    return movements;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAmountRaised = async (movementId) => {
  try {
    const db = getFirestore();
    const docRef = doc(db, 'payments_dev', movementId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const docData = docSnapshot.data();
      return docData;
      // console.log(docData);
    } else {
      console.log('Payment not found.');
    }
  } catch (error) {
    console.error('Error retrieving user data:', error);
  }
};

export const getMovementDonationByMovementId = async (movementId) => {
  const db = firestore;
  const collectionRef = collection(db, 'payments_dev');

  try {
    const docSnapshot = await getDoc(doc(collectionRef, movementId));
    if (docSnapshot.exists()) {
      return [
        {
          id: docSnapshot.id,
          data: docSnapshot.data(),
        },
      ];
    } else {
      return []; // Return an empty array if the document with the given ID doesn't exist
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getuserInfo = async () => {
  const db = firestore;
  const collectionRef = collection(db, 'MFC_Users');
  let userInfo = [];
  try {
    const docsSnap = await getDocs(collectionRef);
    docsSnap.forEach((doc) => {
      userInfo.push({
        id: doc.id,
        data: doc.data(),
      });
    });
    return userInfo;
  } catch (err) {
    console.log(err);
  }
  // console.log(userInfo);
};

export const updateUserInfo = async (docID, object) => {
  try {
    // console.log('in firebase functions');
    // console.log(docID);
    // console.log(object);
    const db = getFirestore();
    const docRef = doc(db, 'MFC_Users', docID);
    updateDoc(docRef, object).then((docRef) => {
      console.log(
        'A New Document Field has been added to an existing document'
      );
    });
  } catch (error) {
    console.error('Error adding document: ', error);
  }
};

export const getMovementInfo = async (uid) => {
  try {
    const db = getFirestore();
    const docRef = doc(db, 'MFC_Movement', uid);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const docData = docSnapshot.data();
      return docData;
      // console.log(docData);
    } else {
      console.log('Movement not found.');
    }
  } catch (error) {
    console.error('Error retrieving user data:', error);
  }
};

export const checkStravaStatus = async (uid) => {
  try {
    const db = getFirestore();
    const docRef = doc(db, 'strava_dev', uid);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const docData = docSnapshot.data();
      // console.log('strava status docdata: ', docData);
      return docData;
    }
  } catch (error) {
    console.error('Strava status error: ', error);
  }
};

export const disconnectStrava = async (uid) => {
  const db = firestore;
  const collectionRef = collection(db, `strava_dev/${uid}/tokens`);
  const stravaDevDocRef = doc(db, 'strava_dev', uid);

  try {
    const subCollectionSnapshot = await getDocs(collectionRef);
    subCollectionSnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref); // Delete the document in the subcollection
      console.log('Document deleted:', doc.id);
    });

    await updateDoc(stravaDevDocRef, { stravaUid: null });
    console.log('All documents in subcollection deleted successfully.');

    window.location.reload().then(() => {
      window.location.href = '/';
    });
  } catch (err) {
    console.error('Error deleting documents:', err);
  }
};
