import styles from '../styles/button.module.css';

const Button = (props) => {
  return (
    <button
      className={props.classN ? props.classN : styles.btn}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
