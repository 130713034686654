export const changePage = (func, directory) => {
  func(directory);
};

export const truncate = (input) => {
  return input?.length > 20 ? `${input.substring(0, 18)}...` : input;
};
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    month: 'short', // abbreviated month name
    day: 'numeric', // numeric day of the month
    ordinal: 'numeric', // numeric representation of the day's ordinal suffix
  };
  const formattedDate = date.toLocaleDateString('en-US', options);

  // Extract the day's ordinal suffix
  const day = formattedDate.slice(0, -2);
  let ordinalSuffix;

  // Handle special cases for 11th, 12th, 13th
  if (day !== '11' && day !== '12' && day !== '13') {
    const lastDigit = day.slice(-1);

    switch (lastDigit) {
      case '1':
        ordinalSuffix = 'st';
        break;
      case '2':
        ordinalSuffix = 'nd';
        break;
      case '3':
        ordinalSuffix = 'rd';
        break;
      default:
        ordinalSuffix = 'th';
    }
  } else {
    ordinalSuffix = 'th';
  }

  // Concatenate the ordinal suffix with the rest of the formatted date
  const formattedDateWithSuffix = formattedDate + ordinalSuffix;

  return formattedDateWithSuffix;
};

export const compareDates = (date) => {
  const today = new Date();
  const movementDate = new Date(date);

  return movementDate < today;
};

export const autoCapitalize = (str) => {
  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
};
