import React from 'react';
import styles from '../styles/movementDetails.module.css';
import { FaGift } from 'react-icons/fa6';

const DonationItem = (props) => {
  return (
    <>
      {/* <p>Amount Paid: {props.amountPaid}</p> */}
      {/* <p>Payment Intent: {props.paymentIntent}</p> */}

      <div className={styles.donation_body}>
        <FaGift size={20} color={'F1EC08'} />
        <span className={styles.donation_text}>
          Someone donated ${props.amountPaid} to this movement
        </span>
      </div>
    </>
  );
};

export default DonationItem;
