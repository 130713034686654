import React, { useState } from 'react';
import styles from '../styles/accordion.module.css';

const Accordion = ({ paymentOptions, onPaymentMethodChange }) => {
  const [selectedOption, setSelectedOption] = useState('credit_card');

  const handleOptionChange = (optionId) => {
    setSelectedOption(optionId);
    onPaymentMethodChange(optionId); // This will update the parent state
  };

  return (
    <div className={styles.paymentOptions}>
      {paymentOptions.map((option) => {
        // Add a conditional class if this is the selected option
        const paymentOptionClass =
          selectedOption === option.id
            ? `${styles.paymentOption} ${styles.selectedOption}`
            : styles.paymentOption;

        return (
          <label key={option.id} className={paymentOptionClass}>
            <input
              type='radio'
              name='paymentMethod'
              value={option.id}
              checked={selectedOption === option.id}
              onChange={() => handleOptionChange(option.id)}
              className={styles.hiddenRadio}
            />
            <span className={styles.customRadio}></span>
            {option.label}
          </label>
        );
      })}
    </div>
  );
};

export default Accordion;
