import style from '../styles/movementCard.module.css';
import { truncate, autoCapitalize } from '../functions/functions';
import { Link } from 'react-router-dom';

const MovementCard = (props) => {
  const handleMovementClick = (movementId) => {
    console.log('Clicked on movement with ID:', movementId);
  };
  return (
    <>
      <ul className={style.card_body} ref={props.cardListRef}>
        {props.displayMovements.length > 0 &&
          !props.isLoading &&
          props.displayMovements.map((movement) => {
            return (
              <li key={movement.id}>
                <div className={style.card_spacing}>
                  <div className={style.card}>
                    <img
                      src={require('../assets/Images/movementbackground-min.jpeg')}
                      alt='background'
                      className={style.background}
                    />
                    <img
                      src={movement.data.userPhotoUrl}
                      alt='Avatar'
                      className={style.avatar}
                    />

                    <span className={style.movement_name}>
                      {autoCapitalize(
                        truncate(movement.data.formSubmission.movementName)
                      )}
                    </span>
                    <div className={style.details}>
                      <div className={style.card_details}>
                        <span className={style.card_title_one}>Charity</span>

                        <span className={style.card_title_one}>Goal</span>
                      </div>
                      <div className={style.card_detail_2}>
                        <span className={style.card_title_two}>
                          {truncate(movement.data.formSubmission.charity)}
                        </span>
                        <span className={style.card_title_two}>
                          ${movement.data.formSubmission.money}
                        </span>
                      </div>
                    </div>
                    <div className={style.card_btn_body}>
                      <Link
                        className={style.card_link}
                        to={`/movement/${movement.id}`}
                      >
                        <button
                          className={style.card_btn}
                          onClick={() => handleMovementClick(movement.id)}
                        >
                          Movement Details
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        {props.displayMovements.length <= 0 && !props.isLoading && (
          <div className={style.no_movements}>No Movements available</div>
        )}
      </ul>
    </>
  );
};

export default MovementCard;
