//stylesheet
import styles from '../../styles/form.module.css';

//ui components
import FormInput from '../../layouts/FormInput';
import { movements } from '../../constants/movements';
import SelectUI from '../../layouts/Select';
import ScrollToTop from '../../components/ScrollToTop';

const CreateMovementPageThree = (props) => {
  const today = new Date().toISOString().slice(0, 10);

  return (
    <>
      <ScrollToTop />

      <h2 className={styles.subtitle1}>How much would you like to raise ?</h2>
      <div className={styles.inputDivider}>
        <FormInput
          jsonData={'money'}
          label={'$ Your Starting Goal'}
          type='number'
          min={100}
          step={1}
          register={props.register}
          required
        />
      </div>
      <h2 className={styles.subtitle1}>Movement Mode and Distance Rate </h2>

      <div className={styles.inputDivider}>
        {/* <FormInput
          label={'Movement Type'}
          jsonData={'movementType'}
          register={props.register}
          required
        /> */}
        <SelectUI
          json={movements}
          label='Movement Type'
          {...props.register('movementType')}
        />
        <FormInput
          label={'$ Amount Per KM'}
          jsonData={'amtPerKm'}
          type='number'
          register={props.register}
          min={0}
          step={0.1}
          required
        />
      </div>
      <h2 className={styles.subtitle1}>Movement Duration</h2>
      <div className={styles.inputDivider}>
        <FormInput
          label={'Start Date'}
          type='date'
          min={today}
          jsonData={'startDate'}
          register={props.register}
          required
        />
        <FormInput
          label={'End Date'}
          type='date'
          min={today}
          jsonData={'endDate'}
          register={props.register}
          required
        />
      </div>
    </>
  );
};

export default CreateMovementPageThree;
