import React, { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import HomeComponent from '../components/HomePageComponent';
import MSOne from '../pages/screen/MovementScreenOne';
import MSTwo from '../pages/screen/MovementScreenTwo';
import MSThree from '../pages/screen/MovemenScreenThree';
import MSFour from '../pages/screen/MovementScreenFour';
import MovementsPage from '../pages/Movements';
import MSFive from '../pages/screen/MovementScreenFive';
import Settings from '../components/Settings';
import MovementDetails from '../pages/MovementDetails';
import { UserContext } from '../components/UserContext';
import CreateSuccess from '../pages/movements/CreateSuccess';
import SuccessPage from '../pages/donations/DonationSuccess';
import ErrorPage from '../pages/error/ErrorPage';
import ExchangeToken from '../pages/strava/ExchangeToken';
import DonationOptions from '../pages/donations/MakeDonation';
import PaymentOptions from '../pages/donations/MakePayment';
import AboutPage from '../pages/About';
import CharityPage from '../pages/CharityPage';

const RequireAuth = ({ children, path }) => {
  const context = useContext(UserContext);

  return context.isLoggedIn === true ? (
    children
  ) : (
    <Navigate to={path ? path : '/'} replace />
  );
};

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<HomeComponent />} />
      <Route path='/make/donation/:id' element={<DonationOptions />} />
      <Route path='/make/payment/:id' element={<PaymentOptions />} />
      {/* Temp URL For Dev No Error On Navigation */}
      {/* <Route path='/donations' element={<HomeComponent />} /> */}
      {/* <Route path='/charities' element={<HomeComponent />} /> */}
      {/* <Route path='/about' element={<HomeComponent />} /> */}
      {/* End of Temp URL REdirection */}
      <Route path='/create' element={<MSOne />} />
      <Route path='/success' element={<SuccessPage />} />

      <Route path='/charities' element={<CharityPage />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/error' element={<ErrorPage />} />
      <Route path='/exchange/token' element={<ExchangeToken />} />
      <Route
        path='/create/movement/new'
        element={
          <RequireAuth path='/create'>
            <MSTwo />
          </RequireAuth>
        }
      />
      <Route
        path='/create/movement/details'
        element={
          <RequireAuth path='/create'>
            <MSThree />
          </RequireAuth>
        }
      />
      <Route
        path='/create/movement/confirm'
        element={
          <RequireAuth path='/create'>
            <MSFour />
          </RequireAuth>
        }
      />
      <Route
        path='/create/movement/final'
        element={
          <RequireAuth path='/create'>
            <MSFive />
          </RequireAuth>
        }
      />
      <Route
        path='/create/movement/success/:id'
        element={
          <RequireAuth path='/create'>
            <CreateSuccess />
          </RequireAuth>
        }
      />
      <Route path='/movements' element={<MovementsPage />} />
      <Route
        path='/settings'
        element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        }
      />
      <Route path='/movement/:id' element={<MovementDetails />} />
      <Route path='*' element={<ErrorPage />} />
    </Routes>
  );
};

export default Router;
