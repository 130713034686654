import CreateScreen from '../CreateScreen';
import CreateMovementPageTwo from '../page/CreateMovementPageTwo';

const MSTwo = () => {
  return (
    <>
      <CreateScreen
        back
        step
        stepCount='1 of 3'
        stepTitleMain='Tell us a bit more about your movement'
        stepTitleSub='This information helps us get to know you and your fundraising needs.'
        formTitle='Create A Movement'
        component={CreateMovementPageTwo}
        keyVal={'MSTwo'}
        page={'/create/movement/details'}
      />
    </>
  );
};
export default MSTwo;
