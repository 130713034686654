import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NavBar from '../Navbar';
import Footer from '../Footer';

// styles
import styles from '../../styles/success.module.css';
import loadingSpinner from '../../assets/Images/custom-spinner.svg';

const SuccessPage = () => {
  const [paymentData, setPaymentData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const movementId = searchParams.get('movementId');

  // console.log(movementId);

  const goToNewMovement = () => {
    navigate('/movement/' + movementId);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentId = urlParams.get('paymentId');
    const movementId = urlParams.get('movementId');
    const userID = urlParams.get('tokenId');
    const queries = {
      payment: paymentId,
      movement: movementId,
      user: userID,
    };

    try {
      setPaymentData(queries);
    } catch (error) {
      setError('Error parsing payment data');
    }
    setIsLoading(false);
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!paymentData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <NavBar />

      {!isLoading && (
        <div className={styles.container}>
          <svg
            class={styles.logo}
            width='279'
            height='179'
            viewBox='0 0 179 179'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M164.085 89.4998C164.085 130.692 130.694 164.083 89.5014 164.083C77.5681 164.083 66.2911 161.279 56.2969 156.297C53.6568 154.932 50.6092 154.579 47.7273 155.305L31.125 159.75C29.4817 160.188 27.752 160.187 26.1095 159.746C24.467 159.304 22.9694 158.439 21.7672 157.236C20.5649 156.033 19.7002 154.535 19.26 152.892C18.8197 151.249 18.8193 149.519 19.2588 147.876L23.6965 131.274C24.4183 128.394 24.0629 125.349 22.6971 122.712C17.563 112.394 14.8998 101.024 14.9181 89.4998C14.9181 48.3075 48.309 14.9165 89.5014 14.9165C130.694 14.9165 164.085 48.3075 164.085 89.4998ZM89.5014 54.0728C90.9849 54.0728 92.4077 54.6621 93.4568 55.7111C94.5058 56.7602 95.0951 58.1829 95.0951 59.6665V59.7411C103.217 61.7921 110.012 68.1914 110.012 77.0668C110.012 78.5504 109.422 79.9731 108.373 81.0222C107.324 82.0712 105.902 82.6605 104.418 82.6605C102.935 82.6605 101.512 82.0712 100.463 81.0222C99.4137 79.9731 98.8243 78.5504 98.8243 77.0668C98.8243 74.2028 95.6471 70.235 89.5014 70.235C83.3557 70.235 80.1785 74.2028 80.1785 77.0668C80.1785 79.9308 83.3557 83.9061 89.5014 83.9061C99.8312 83.9061 110.012 91.0661 110.012 101.933C110.012 110.808 103.217 117.215 95.0951 119.259V119.333C95.0951 120.817 94.5058 122.24 93.4568 123.289C92.4077 124.338 90.9849 124.927 89.5014 124.927C88.0178 124.927 86.595 124.338 85.546 123.289C84.497 122.24 83.9076 120.817 83.9076 119.333V119.259C75.7855 117.215 68.991 110.808 68.991 101.933C68.991 100.449 69.5803 99.0265 70.6293 97.9775C71.6784 96.9285 73.1012 96.3391 74.5847 96.3391C76.0683 96.3391 77.4911 96.9285 78.5401 97.9775C79.5891 99.0265 80.1785 100.449 80.1785 101.933C80.1785 104.797 83.3557 108.765 89.5014 108.765C95.6471 108.765 98.8243 104.797 98.8243 101.933C98.8243 99.0689 95.6471 95.0936 89.5014 95.0936C79.1716 95.0936 68.991 87.9336 68.991 77.0668C68.991 68.1914 75.7855 61.7921 83.9076 59.7411V59.6665C83.9076 58.1829 84.497 56.7602 85.546 55.7111C86.595 54.6621 88.0178 54.0728 89.5014 54.0728Z'
              fill='#7DF108'
            />
          </svg>

          <span className={styles.text1}>Thank You!</span>
          <span className={styles.text3}>Your donation was successful!</span>
          <button className={styles.btn} onClick={goToNewMovement}>
            Continue
          </button>
        </div>
      )}
      {isLoading && (
        <div className={styles.loading_container}>
          <div className={styles.loading_body}>
            <img
              src={loadingSpinner}
              alt='loading'
              className={styles.loading}
            />
          </div>
        </div>
      )}
      {!isLoading && <Footer />}
    </>
  );
};

export default SuccessPage;
