import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

//custom imports
import {
  FaBars,
  FaTimes,
  FaUserCircle,
  FaRegTimesCircle,
} from 'react-icons/fa';
import navItems from '../constants/navItems';
import { UserContext } from '../components/UserContext';
import CustomLink from '../routes/CustomLink';
import AuthBtn from '../layouts/AuthBtn';
import Button from '../layouts/Button';
import { useMediaQuery } from 'react-responsive';
//stylesheets
import styles from '../styles/navbar.module.css';
import { logout, signInWithGoogle } from '../config/firebaseConfig';


const NavBar = (props) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 766 });
  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 990 });
  const isDesktop = useMediaQuery({ minWidth: 991 });

  const menu = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const context = useContext(UserContext);

  return (
    <>
      <div className={styles.nav_container}>
        {isMobile && (
          <div className={styles.navigation}>
            {!isNavExpanded ? (
              <FaBars
                className={styles.hamburger}
                color='#fff'
                onClick={menu}
                size={35}
              />
            ) : (
              <FaTimes
                className={styles.hamburger}
                color='#f9ff00'
                onClick={menu}
                size={35}
              />
            )}
            <Link to='/'><img
              src={require('../assets/Images/logo.png')}
              className={styles.logo}
              alt='move for change logo'
            /></Link>
          </div>
        )}
        {isTablet && (
          <div className={styles.navigation}>
            {!isNavExpanded ? (
              <FaBars
                className={styles.hamburger}
                color='#fff'
                onClick={menu}
                size={35}
              />
            ) : (
              <FaTimes
                className={styles.hamburger}
                color='#f9ff00'
                onClick={menu}
                size={35}
              />
            )}
            <div className={styles.tablet_menu}>
            <Link to='/'><img
                src={require('../assets/Images/logo.png')}
                className={styles.logo}
                alt='move for change logo'
              /></Link>
              {context.isLoggedIn && (
                <div className={styles.auth_items}>
                  {context.photoUrl !== ' ' ? (
                    <Link to='/settings'>
                      <img
                        src={context.photoUrl}
                        alt='user'
                        className={styles.userImage}
                      />
                    </Link>
                  ) : (
                    <FaUserCircle className={styles.temp_user_logo} />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {isDesktop && (
          <div className={styles.desktop_navigation}>
            <ul className={styles.list}>
            <Link to='/'><img
                src={require('../assets/Images/logo.png')}
                className={styles.logo}
                alt='move for change logo'
              /></Link>
              <div className={styles.list_menu}>
                {/* <div>
                  
                </div> */}
              </div>
              {navItems.map((item, index) => {
                return (
                  <CustomLink key={index} to={item.url}>
                    {item.title}
                  </CustomLink>
                );
              })}
              {
                <Link to='/create'>
                  <Button classN={styles.create_movement_btn}>
                    Create Movement
                  </Button>
                </Link>
              }
              {context.isLoggedIn && (
                <div className={styles.auth_items}>
                  {context.photoUrl !== ' ' ? (
                    <Link to='/settings'>
                      <img
                        src={context.photoUrl}
                        alt='user'
                        className={styles.userImage}
                      />
                    </Link>
                  ) : (
                    <FaUserCircle className={styles.temp_user_logo} />
                  )}
                </div>
              )}
              {context.isLoggedIn && (
                <AuthBtn
                  className={styles.auth_btn_desktop}
                  method={logout}
                  title='Sign Out'
                />
              )}
              {!context.isLoggedIn && (
                <AuthBtn
                  className={styles.auth_btn_desktop}
                  method={signInWithGoogle}
                  title='Sign In'
                />
              )}
            </ul>
          </div>
        )}
        {isNavExpanded && (
          <div
            className={
              isNavExpanded
                ? `${styles.expanded} `
                : `${styles.navigation_menu_close}`
            }
          >
            <ul className={styles.list}>
              <div className={styles.list_menu}>
                {!context.isLoggedIn && (
                  <div className={styles.auth_items}>
                    <FaUserCircle className={styles.temp_user_logo} />
                  </div>
                )}
                <div>
                  {context.isLoggedIn && (
                    <div className={styles.auth_items}>
                      {context.photoUrl !== ' ' ? (
                        <Link to='/settings'>
                          <img
                            src={context.photoUrl}
                            alt='user'
                            className={styles.userImage}
                          />
                        </Link>
                      ) : (
                        <FaUserCircle className={styles.temp_user_logo} />
                      )}
                    </div>
                  )}
                </div>

                <div>
                  {
                    <FaRegTimesCircle
                      className={styles.hamburger}
                      color='#fff'
                      onClick={menu}
                      size={35}
                    />
                  }
                </div>
              </div>
              {navItems.map((item, index) => {
                return (
                  <CustomLink key={index} to={item.url}>
                    {item.title}
                  </CustomLink>
                );
              })}
              {context.isLoggedIn && (
                <AuthBtn
                  className={styles.auth_btn}
                  method={logout}
                  title='Sign Out'
                />
              )}
              {!context.isLoggedIn && (
                <AuthBtn
                  className={styles.auth_btn}
                  method={signInWithGoogle}
                  title='Sign In'
                />
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default NavBar;

// {
//   /* <span className={styles.navbar_btns}>
<Link to='/create'>
  <Button classN={styles.create_movement_btn}>Create Movement</Button>
</Link>;
//   {!context.isLoggedIn ? (
//     <AuthBtn
//       className={styles.navbar_btn}
//       method={signInWithGoogle}
//       title='Sign In'
//     />
//   ) : (
//     <span className={styles.navbar_loggedIn}>

//     </span>
//   )}
// </span> */
// }
