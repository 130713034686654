import CreateScreen from '../CreateScreen';
import CreateMovementPageThree from '../page/CreateMovementPageThree';

const MSThree = () => {
  return (
    <>
      <CreateScreen
        back
        step
        stepCount='2 of 3'
        stepTitleMain='How much would you like to raise?'
        stepTitleSub="It's completely expected to need funds beyond your starting goal. You can always change your goal as you go."
        formTitle='Movement Details'
        component={CreateMovementPageThree}
        keyVal={'MSThree'}
        page={'/create/movement/final'}
      />
    </>
  );
};
export default MSThree;
