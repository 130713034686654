//custom stylesheets
import styles from '../styles/homepage.module.css';

//custom imports
import Button from '../layouts/Button';
import { Link } from 'react-router-dom';

const HomePageSectionOne = (props) => {
  return (
    <>
      <div className={styles.section_one}>
        {props.children}
        <div className={styles.s1_body_container}>
          <div className={styles.s1_body}>
            <h1 className={styles.s1_title}>Move For Change</h1>
            <p className={styles.s1_subtitle}>
              “ As Move For Change strives to unite individuals around the world
              for a greater purpose, it is up to each and every one of us to be
              the change we want to see. Will you answer the challenge? Are you
              ready to compete for something bigger than yourself? Join the
              community and see how far you can push your limits – all in
              support of a good cause. ”
            </p>
            <Link to='/create' className={styles.btn}>
              <Button classN={styles.create_movement_btn}>
                Create A Movement
              </Button>
            </Link>
          </div>
          <div className={styles.parent}>
            <div className={styles.img_body}>
              <img
                className={styles.image1}
                src={require('../assets/Images/movements.png')}
              />
              <img
                className={styles.image2}
                src={require('../assets/Images/create.png')}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageSectionOne;
