import { NavLink, useResolvedPath, useMatch } from 'react-router-dom';

import styles from '../styles/customlink.module.css';

const CustomLink = ({ to, children, ...props }) => {
  const resolvedPath = useResolvedPath(to);
  // const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <NavLink
      to={to}
      {...props}
      className={styles.list}
      style={({ isActive }) => {
        // return { color: isActive ? '#3dc2ff' : '#fff' };
        return { color: isActive ? '#F1EC08' : '#fff' };
      }}
    >
      {children}
    </NavLink>
  );
};

export default CustomLink;
