import { useNavigate, useLocation } from 'react-router-dom';

import styles from '../../styles/success.module.css';
import Footer from '../Footer';
import NavBar from '../Navbar';

const CreateSuccess = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;
  const goToNewMovement = () => {
    navigate('/movement/' + id.movement);
  };
  console.log(location.state);
  return (
    <>
      <NavBar />
      <div className={styles.container}>
        <img
          className={styles.logo}
          src={require('../../assets/Images/logo.png')}
          alt='logo'
        />
        <span className={styles.text1}>Congratulations</span>
        <span className={styles.text2}>You created a new movement.</span>
        <span className={styles.text3}>Let's start raising for charity!</span>
        <button className={styles.btn} onClick={goToNewMovement}>
          Continue
        </button>
      </div>
      <Footer />
    </>
  );
};

export default CreateSuccess;
