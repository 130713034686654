//stylesheet
import styles from '../../styles/form.module.css';

//ui components
import FormInput from '../../layouts/FormInput';
import SelectUI from '../../layouts/Select';

// custom imports
import { countries } from '../../constants/countries';
import { charities } from '../../constants/charities';
import ScrollToTop from '../../components/ScrollToTop';

const CreateMovementPageOne = (props) => {
  return (
    <>
      <ScrollToTop />
      <h2 className={styles.subtitle1}>Where are you located ?</h2>
      <div className={styles.inputDivider}>
        <SelectUI
          json={countries}
          label='Country'
          {...props.register('country')}
        />

        {/* <FormInput
          label={'Postal Code'}
          register={props.register}
          required
          pattern={{ pattern: /^\S+@\S+$/i }}
        /> */}
      </div>
      <h2 className={styles.subtitle1}>
        Which Charity would you like to benefit from your movement ?
      </h2>
      <SelectUI
        json={charities}
        label='Charity'
        {...props.register('charity')}
      />
    </>
  );
};

export default CreateMovementPageOne;
