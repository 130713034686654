import React, { useContext, useState, useEffect } from 'react';
import NavBar from '../pages/Navbar';

import styles from '../styles/settings.module.css';
import { UserContext } from './UserContext';
import { useForm } from 'react-hook-form';
import { FaStrava } from 'react-icons/fa';
import { FaCircleCheck } from 'react-icons/fa6';
import Button from '../layouts/Button';
import { stravaLogin } from '../config/StravaLogin';
import { countryList, city } from '../constants/countries';
import {
  checkStravaStatus,
  disconnectStrava,
  getuserInfo,
  updateUserInfo,
} from '../config/FirbaseFunctions';
import Footer from '../pages/Footer';
import loadingSpinner from '../assets/Images/custom-spinner.svg';

const Settings = (props) => {
  const [userData, setUserData] = useState([]);
  const [stravaData, setStravaData] = useState({ stravaUid: null });
  const [isLoading, setIsLoading] = useState(true);

  const context = useContext(UserContext);

  const removeStrava = () => {
    try {
      setIsLoading(true);
      disconnectStrava(context.userID);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const fetchUserInfo = async () => {
    setIsLoading(true);
    try {
      const userInfo = await getuserInfo(context.userID);
      const filteredUserInfo = userInfo.filter((user) => {
        return context.userID === user.data.uid;
      });
      setUserData(filteredUserInfo);
      fetchStravaInfo();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const fetchStravaInfo = () => {
    checkStravaStatus(context.userID).then((data) => {
      if (data && data.stravaUid) {
        setStravaData(data.stravaUid);
      }
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    updateUserInfo(userData[0].id, data);
    console.log(data);
  };

  console.log(errors);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  console.log(stravaData);

  return (
    <>
      <NavBar />
      {!isLoading && (
        <div className={styles.settings_s1}>
          <div className={styles.edit_body}>
            <div className={styles.setting_title_body}>
              <span className={styles.profile}>Profile Settings</span>
            </div>
            <div className={styles.user_body}>
              <div className={styles.user}>
                <img className={styles.photo} src={context.photoUrl} alt='' />
                <p className={styles.userName}>{context.displayName}</p>
              </div>
              <div className={styles.strava}>
                {stravaData.stravaUid !== null ? (
                  <div>
                    <div className={styles.strava_connected}>
                      <FaStrava color='#FC5200' size={20} />
                      <FaCircleCheck color='#7DF108' size={20} />
                      <span>Strava Account Connected</span>
                    </div>
                    <span
                      onClick={removeStrava}
                      className={styles.strava_disconnect}
                    >
                      Click here to disconnect
                    </span>
                  </div>
                ) : (
                  <Button
                    onClick={() => stravaLogin(context.userID)}
                    children={
                      <div className={styles.strava_btn_styles}>
                        <FaStrava color='#FC5200' size={25} />
                        <span>Connect Strava Account</span>
                      </div>
                    }
                    classN={styles.strava_btn}
                  />
                )}
              </div>
            </div>
            <div className={styles.form_container}>
              <div className={styles.form}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles.form_body}>
                    <div className={styles.form_block_one}>
                      <label className={styles.label}>Full Name</label>
                      <input
                        defaultValue={
                          userData.length ? userData[0].data.name : ''
                        }
                        type='text'
                        placeholder='Full Name'
                        {...register('name', {})}
                      />

                      <label className={styles.label}>Country</label>
                      <select
                        className={styles.dropdown}
                        name='Country'
                        {...register('country', {})}
                      >
                        {countryList.map((data) => {
                          return (
                            <option key={data.code} value={data.name}>
                              {data.name}
                            </option>
                          );
                        })}
                      </select>
                      <label className={styles.label}>City</label>
                      <select
                        className={styles.dropdown}
                        name='City'
                        {...register('city', {})}
                      >
                        {city.map((data) => {
                          return (
                            <option key={data.key} value={data.name}>
                              {data.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className={styles.form_block_two}>
                      <label className={styles.label}>Bio</label>
                      <textarea
                        defaultValue={
                          userData.length ? userData[0].data.bio : ''
                        }
                        className={styles.form_textarea}
                        {...register('bio', {})}
                      />
                      <div className={styles.form_btn}>
                        <input
                          className={styles.card_btn}
                          type='submit'
                          value='Save Info'
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <span className={styles.your_movements}>Your Movements</span> */}
          </div>
        </div>
      )}

      {isLoading && (
        <div className={styles.loading_container}>
          <div className={styles.loading_body}>
            <img
              src={loadingSpinner}
              alt='loading'
              className={styles.loading}
            />
          </div>
        </div>
      )}
      {!isLoading && <Footer />}
    </>
  );
};

export default Settings;
