import { useMediaQuery } from 'react-responsive';

import FormUI from '../layouts/Form';
import styles from '../styles/form.module.css';
import Footer from './Footer';
import NavBar from './Navbar';
import StepsPage from './StepsPage';
import ScrollToTop from '../components/ScrollToTop';

const CreateScreen = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 991 });
  return (
    <>
      <ScrollToTop />

      {!isDesktop && <NavBar />}
      <div className={styles.container}>
        <StepsPage
          step={props.step}
          stepCount={props.stepCount}
          stepTitleMain={props.stepTitleMain}
          stepTitleSub={props.stepTitleSub}
        />
        <FormUI
          keyVal={props.keyVal}
          back={props.back}
          formTitle={props.formTitle}
          component={props.component}
          page={props.page}
          btnTitle={props.btnTitle}
          finish={props.finish}
        />
      </div>
      {!isDesktop && <Footer />}
    </>
  );
};

export default CreateScreen;
