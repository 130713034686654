import CreateScreen from '../CreateScreen';
import CreateMovementPageFour from '../page/CreateMovementPageFour';

const MSFive = () => {
  // let one = JSON.parse(sessionStorage.getItem('MSOne'));
  // let two = JSON.parse(sessionStorage.getItem('MSTwo'));
  // let three = JSON.parse(sessionStorage.getItem('MSThree'));
  // console.log('one: ' + one, 'two: ' + two, 'three: ' + three);

  return (
    <>
      <CreateScreen
        back
        step
        stepCount=': Save Changes'
        stepTitleMain='Just One More Step'
        stepTitleSub='Please select "Finish" button to save movement and view movement changes'
        formTitle='Save Movement'
        component={CreateMovementPageFour}
        keyVal={'MSFive'}
        page={'/create/movement/success/'}
        btnTitle='Finish'
        finish
      />
    </>
  );
};
export default MSFive;
