import React from 'react';

//stylesheet
import styles from '../styles/form.module.css';

const SelectUI = React.forwardRef(({ onChange, name, label, json }, ref) => (
  <div className={styles.formItemDivider}>
    <label className={`${styles.filled}`}>{label}</label>
    <div className={styles.inputContainer}>
      <select
        className={styles.input}
        name={name}
        ref={ref}
        onChange={onChange}
      >
        {json.map((data) => {
          return (
            <option key={data.key} value={data.value}>
              {data.name}
            </option>
          );
        })}
      </select>
    </div>
  </div>
));

export default SelectUI;
