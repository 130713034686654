import { useState, useEffect, useContext, useCallback, useRef } from 'react';

//custom imports
import { getAllMovements, getMovementsByUid } from '../config/FirbaseFunctions';
import DataTable, { createTheme } from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { FaStrava } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

//stylesheets
import styles from '../styles/movements.module.css';
import style from '../styles/movementCard.module.css';
import NavBar from './Navbar';
import { UserContext } from '../components/UserContext';
import { compareDates } from '../functions/functions';
import loadingSpinner from '../assets/Images/custom-spinner.svg';
import MovementCard from '../layouts/MovementCard';
import Footer from './Footer';

createTheme('movement', {
  text: {
    primary: '#2d2a32',
  },
  background: {
    default: '#eeefa8',
  },
});

const MovementsPage = (props) => {
  const [yourMovements, setYourMovements] = useState([]);
  const [otherMovements, setOtherMovements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPageYours, setCurrentPageYours] = useState(0);
  const [currentPageOthers, setCurrentPageOthers] = useState(0);
  const cardListYoursRef = useRef(null);
  const cardListOthersRef = useRef(null);
  const isDesktop = useMediaQuery({ minWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 766 });
  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 990 });

  const perPage = 6; // Number of items to display per page
  // Calculate the total number of pages based on the number of movements
  const yourPageCount = Math.ceil(yourMovements.length / perPage);
  const othersPageCount = Math.ceil(otherMovements.length / perPage);

  // Calculate the start and end indices for the current page
  const startIndexYours = currentPageYours * perPage;
  const endIndexYours = startIndexYours + perPage;
  const startIndexOthers = currentPageOthers * perPage;
  const endIndexOthers = startIndexOthers + perPage;

  // Get the movements to display for the current page
  const displayYourMovements = yourMovements.slice(
    startIndexYours,
    endIndexYours
  );
  const displayOtherMovements = otherMovements.slice(
    startIndexOthers,
    endIndexOthers
  );

  const context = useContext(UserContext);

  const scrollYourListToLeft = () => {
    if (cardListYoursRef.current) {
      cardListYoursRef.current.scrollTo({
        left: 0,
        // behavior: 'smooth',
      });
    }
  };
  const scrollOthersListToLeft = () => {
    if (cardListOthersRef.current) {
      cardListOthersRef.current.scrollTo({
        left: 0,
        // behavior: 'smooth',
      });
    }
  };
  const handleMovementClick = (movementId) => {
    console.log('Clicked on movement with ID:', movementId);
  };

  const handlePageChange = () => {
    scrollYourListToLeft();
    scrollOthersListToLeft();
  };

  const fetchYourData = useCallback(async () => {
    setIsLoading(true);
    try {
      const movementsData = await getMovementsByUid(context.userID);
      // console.log(movementsData);
      const yourMovementsArray = [];
      movementsData.map((data) => {
        const movementExpirationCheck = compareDates(
          data.data.formSubmission.endDate
        );
        if (data.data.displayName) {
          if (
            data.data.userID === context.userID &&
            movementExpirationCheck === false
          ) {
            yourMovementsArray.push(data);
            // console.log(data.data.formSubmission.movementName);
          }
        }
      });
      // console.log(yourMovementsArray);
      setIsLoading(false);
      setYourMovements(yourMovementsArray);
    } catch (error) {
      console.log('Error retrieving movements:', error);
    }
  }, []);

  const fetchOtherData = useCallback(async () => {
    setIsLoading(true);
    try {
      const movementsData = await getAllMovements();
      // console.log(movementsData);
      const otherMovementsArray = [];
      movementsData.map((data) => {
        const movementExpirationCheck = compareDates(
          data.data.formSubmission.endDate
        );

        if (data.data.displayName) {
          if (
            data.data.userID !== context.userID &&
            movementExpirationCheck === false
          ) {
            otherMovementsArray.push(data);
          }
        }
      });
      setIsLoading(false);
      setOtherMovements(otherMovementsArray);
    } catch (error) {
      console.log('Error retrieving movements:', error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchYourData();
      await fetchOtherData();
    };

    fetchData();
  }, [
    setCurrentPageYours,
    setCurrentPageOthers,
    setOtherMovements,
    setYourMovements,
    fetchOtherData,
    fetchYourData,
  ]);

  return (
    <>
      <div className={styles.movement_body}>
        <NavBar />
        {!isLoading && context.isLoggedIn && (
          <div className={styles.title}>Your Movements</div>
        )}
        {!isDesktop &&
          displayYourMovements.length > 0 &&
          !isLoading &&
          context.isLoggedIn &&
          !isTablet && <p className={styles.swipe}>Swipe to see more</p>}
        {isLoading && (
          <img src={loadingSpinner} alt='loading' className={styles.loading} />
        )}
        {context.isLoggedIn && !isLoading && (
          <div className={style.movement_cards_body}>
            {/* create this loading spinner into component GH and then add isLoading as a truthy */}

            <MovementCard
              displayMovements={displayYourMovements}
              isLoading={isLoading}
              cardListRef={cardListYoursRef}
            />
            {/* Add the pagination component */}
            {displayYourMovements.length > 0 && !isLoading && (
              <ReactPaginate
                pageCount={yourPageCount}
                pageRangeDisplayed={5} // Number of page links to display
                marginPagesDisplayed={2} // Number of pages to display before/after the current page
                onPageChange={({ selected }) => {
                  handlePageChange();
                  setCurrentPageYours(selected);
                }}
                className={styles.pagination_body}
                activeClassName={styles.active_item}
              />
            )}
          </div>
        )}
        {!isLoading && <div className={styles.title}>Current Movements</div>}
        <div className={style.movement_cards_body}>
          {!isDesktop &&
            displayOtherMovements.length > 0 &&
            !isLoading &&
            context.isLoggedIn &&
            !isTablet && <p className={styles.swipe}>Swipe to see more</p>}
          {/* create this loading spinner into component GH and then add isLoading as a truthy */}
          {/* {isLoading && (
          <img src={loadingSpinner} alt='loading' className={styles.loading} />
        )} */}
          <MovementCard
            displayMovements={displayOtherMovements}
            isLoading={isLoading}
            cardListRef={cardListOthersRef}
          />
          {/* Add the pagination component */}
          {displayOtherMovements.length > 0 && !isLoading && (
            <ReactPaginate
              pageCount={othersPageCount}
              pageRangeDisplayed={5} // Number of page links to display
              marginPagesDisplayed={2} // Number of pages to display before/after the current page
              onPageChange={({ selected }) => {
                handlePageChange();
                setCurrentPageOthers(selected);
              }}
              className={styles.pagination_body}
              activeClassName={styles.active_item}
            />
          )}
          {!isLoading && <Footer />}
        </div>
      </div>
    </>
  );
};

export default MovementsPage;
