import React, { useContext, useState } from 'react';
import { useNavigate, redirect } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// stylesheets
import styles from '../styles/form.module.css';

//Custom Imports
import { useForm } from 'react-hook-form';
import FormBtn from './FormBtn';
import { FaUserCircle } from 'react-icons/fa';
import { signInWithGoogle } from '../config/firebaseConfig';
import AuthBtn from './AuthBtn';
import { UserContext } from '../components/UserContext';
import loadingSpinner from '../assets/Images/custom-spinner.svg';
import {
  createMovement,
  getCurrentDate,
  getuCurrentUserID,
} from '../config/FirbaseFunctions';

const FormUI = (props) => {
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 991 });
  const [isLoading, setIsLoading] = useState(false);
  // console.log(context.userID);

  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    console.log('submit clicked');
    sessionStorage.setItem(props.keyVal, JSON.stringify(data));
    console.log(props.keyVal + '  ' + JSON.stringify(data));
    console.log(props.page);
    if (context.isLoggedIn) {
      if (!props.finish) {
        navigate(props.page);
      }
    } else {
      alert(
        'Please ensure that you are logged in to continue with creating movement'
      );
    }
  };

  // const goBack = () => {
  //   navigate(-1);
  // };

  const submitData = async () => {
    let one = JSON.parse(sessionStorage.getItem('MSOne'));
    let two = JSON.parse(sessionStorage.getItem('MSTwo'));
    let three = JSON.parse(sessionStorage.getItem('MSThree'));
    let four = JSON.parse(sessionStorage.getItem('MSFour'));

    let formDataObject = {
      userID: context.userID,
      displayName: context.displayName,
      userPhotoUrl: context.photoUrl,
      createdAt: getCurrentDate(),
      donations: [],
      formSubmission: { ...one, ...two, ...three, ...four },
    };

    console.log(formDataObject);

    if (props.finish) {
      setIsLoading(true);
      const submissionTime = getCurrentDate();
      let finalFormObject = {
        submissionTime: submissionTime,
        ...formDataObject,
      };
      if (context.isLoggedIn) {
        try {
          const result = await createMovement(finalFormObject);
          console.log('createMovement result: ', result);
          console.log(result);
          if (result.data.success) {
            // Successful data submission
            console.log(result.data.message);
            reset();
            sessionStorage.clear();
            console.log('Movement created successfully: ', result);
            navigate(props.page + result.data.id, {
              state: { movement: result.data.id },
            });
            setIsLoading(false);
          } else {
            // Error in data submission
            console.log('Error saving data: ' + result.message);
            if (result.errors) {
              console.log('Validation errors:', result.errors);
              // Handle validation errors here if necessary
            }
          }
        } catch (error) {
          console.error('Error saving data: ', error);
          alert('Error saving data. Please try again.');
        }
      } else {
        console.log('Could not create movement ');
        alert(
          "Couldn't create movement. Please ensure that you are logged in."
        );
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loading_container}>
          <img src={loadingSpinner} alt='loading' className={styles.loading} />
          <span className={styles.loading_text}>
            Please wait your movement is being created ....{' '}
          </span>
        </div>
      )}

      {!isLoading && (
        <div className={styles.user_icon_container}>
          {isDesktop &&
            context.isLoggedIn &&
            (context.photoUrl !== '' ? (
              <img
                src={context.photoUrl}
                alt='user'
                className={styles.userImage}
              />
            ) : (
              <FaUserCircle className={styles.logo2} />
            ))}

          {isDesktop && !context.isLoggedIn && (
            <AuthBtn
              className={styles.auth_btn}
              method={signInWithGoogle}
              title='SignIn'
            />
          )}
        </div>
      )}
      {!isLoading && (
        <form className={styles.section2} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_container}>
            <h1 className={styles.title1}>{props.formTitle}</h1>
            <props.component register={register} />
            <div className={styles.bottom_btn}>
              {/* <div className={styles.line}> </div> */}
              <div className={styles.form_btns}>
                {/* {props.back && (
              <FormBtn title='Back' type='submit' onClick={goBack} />
            )} */}
                <FormBtn
                  title={props.btnTitle ? props.btnTitle : 'Continue'}
                  type='submit'
                  onClick={submitData}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default FormUI;
