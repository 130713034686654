export const charities = [
  {
    key: '1',
    name: 'Black Business and Professionals Association',
    value: 'Black Business and Professionals Association',
    website: '',
  },
  {
    key: '2',
    name: 'TROPICANA COMMUNITY SERVICES',
    value: 'TROPICANA COMMUNITY SERVICES',
    website: '',
  },
  {
    key: '3',
    name: 'For Youth Initiative',
    value: 'For Youth Initiative',
    website: '',
  },
  {
    key: '4',
    name: 'Keep6ix',
    value: 'Keep6ix',
    website: '',
  },
  {
    key: '5',
    name: 'Rotary International',
    value: 'Rotary International',
    website: '',
  },  {
    key: '6',
    name: 'YOUTH ASSISTING YOUTH',
    value: 'YOUTH ASSISTING YOUTH',
    website: '',
  },  {
    key: '7',
    name: 'The CEE Centre For Young Black Professionals',
    value: 'The CEE Centre For Young Black Professionals',
    website: '',
  },  {
    key: '8',
    name: 'Black Youth Helpline (BYH)',
    value: 'Black Youth Helpline (BYH)',
    website: '',
  }, {
    key: '9',
    name: 'Inner City Outreach',
    value: 'Inner City Outreach',
    website: '',
  },{
    key: '10',
    name: 'The Kickback',
    value: 'The Kickback',
    website: '',
  },{
    key: '11',
    name: 'Eva\'s',
    value: 'Eva\'s',
    website: '',
  },{
    key: '12',
    name: 'Youth Leaps',
    value: 'Youth Leaps',
    website: '',
  },{
    key: '13',
    name: 'The Pinball Clemons Foundation',
    value: 'The Pinball Clemons Foundation',
    website: '',
  },{
    key: '14',
    name: 'Caribbean African Canadian Social Services',
    value: 'Caribbean African Canadian Social Services',
    website: '',
  },{
    key: '15',
    name: 'Native Child and Family Services of Toronto',
    value: 'Native Child and Family Services of Toronto',
    website: '',
  }

  
];

//   {
//     key: '',
//     name: '',
//     website: '',
//   },
