const AuthBtn = (props) => {
  return (
    <>
      <button className={props.className} onClick={props.method}>
        {props.title}
      </button>
    </>
  );
};

export default AuthBtn;
