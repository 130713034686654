import CreateScreen from '../CreateScreen';
import CreateMovementPageFour from '../page/CreateMovementPageFour';

const MSFour = () => {
  // let one = JSON.parse(sessionStorage.getItem('MSOne'));
  // let two = JSON.parse(sessionStorage.getItem('MSTwo'));
  // let three = JSON.parse(sessionStorage.getItem('MSThree'));
  // console.log('one: ' + one, 'two: ' + two, 'three: ' + three);

  return (
    <>
      <CreateScreen
        back
        step
        stepCount='3 of 3'
        stepTitleMain='Thank you for creating movement!'
        stepTitleSub='Please confirm changes so your movement can be active and available to the world'
        formTitle='Confirm Movement'
        component={CreateMovementPageFour}
        keyVal={'MSFour'}
        page={'/create/movement/final'}
        btnTitle='Confirm'
        // finish
      />
    </>
  );
};
export default MSFour;
