// import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
//stylesheet
import FormInput from '../../layouts/FormInput';
import styles from '../../styles/form.module.css';
import ScrollToTop from '../../components/ScrollToTop';

//custom functions
// import { stravaLogin } from '../../config/StravaLogin';

const CreateMovementPageFour = (props) => {
  const [diffDays, setDiffDays] = useState(null);
  // const location = useLocation();
  // const params = new URLSearchParams(location.search);

  let one = JSON.parse(sessionStorage.getItem('MSOne'));
  let two = JSON.parse(sessionStorage.getItem('MSTwo'));
  let three = JSON.parse(sessionStorage.getItem('MSThree'));
  let four = JSON.parse(sessionStorage.getItem('MSFour'));
  let formDataObject = { ...one, ...two, ...three, ...four };
  localStorage.setItem('confirmed', formDataObject);

  useEffect(() => {
    const fetchDate = async () => {
      try {
        if (three.endDate && three.startDate) {
          const endingDate = await new Date(three.endDate);
          console.log(three.endDate);
          // const endDay = endDate.getDate() + 1;

          const startingDate = await new Date(three.startDate);
          // const startDay = startDate.getDate() + 1;

          const timeDiff = Math.abs(
            startingDate.getTime() - endingDate.getTime()
          );
          const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          setDiffDays(diffDays);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDate();
  }, []);

  // console.log(formDataObject);

  // const runStrava = () => {
  //   stravaLogin().then(() => {
  //     const code = params.get('success');
  //     console.log(code);
  //   });
  // };

  return (
    <>
      <ScrollToTop />

      <h2 className={styles.subtitle1}>
        Title: {formDataObject ? formDataObject['movementName'] : ''}{' '}
      </h2>
      <h2 className={styles.subtitle1}>
        Charity: {formDataObject ? formDataObject['charity'] : ''}{' '}
      </h2>
      <h2 className={styles.subtitle1}>
        Goal: {formDataObject ? '$' + formDataObject['money'] : ''}{' '}
      </h2>
      <h2 className={styles.subtitle1}>
        Location: {formDataObject ? formDataObject['country'] : ''}{' '}
      </h2>
      <h2 className={styles.subtitle1}>
        Amount Per KM: {formDataObject ? formDataObject['amtPerKm'] : ''}{' '}
      </h2>
      <h2 className={styles.subtitle1}>
        Movement Duration: {diffDays + ' days'}
        {/* {formDataObject ? startDay + 'th' : ''} to{' '}
        {formDataObject ? endDay : ''} */}
      </h2>
      {/* <FormInput
        type='radio'
        jsonData={'public'}
        register={props.register}
        value={true}
        required
      />
      <FormInput
        type='radio'
        jsonData={'donationA'}
        value={true}
        register={props.register}
        required
      /> */}
    </>
  );
};

export default CreateMovementPageFour;
