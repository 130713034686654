export const movements = [
  {
    key: 'run',
    name: 'Running',
    value: 'Running',
  },
  //   {
  //     key: 'swim',
  //     name: 'swimming',
  //   },
  //   {
  //     key: 'bike',
  //     name: 'cycling',
  //   },
];
