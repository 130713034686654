//custom stylesheets
import styles from '../styles/homepage.module.css';
import { useMediaQuery } from 'react-responsive';
//custom imports
import Button from '../layouts/Button';
import { Link } from 'react-router-dom';

const HomePageSectionThree = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 766 });
  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 990 });

  return (
    <>
      <div className={styles.section_three}>
        {isMobile && (
          <div className={styles.s3_body}>
            <img
              className={styles.image3}
              src={require('../assets/Images/s3-walking.jpg')}
            />
          </div>
        )}

        <div className={styles.s3_inner_body}>
          <div className={styles.s3_paragraph}>
            <h1 className={styles.s3_title}>
              We have raised more than{' '}
              <span className={styles._18k}>$20000</span> CAD since June 2020.
            </h1>
            <p className={styles.s3_subtitle}>
              Our appreciation for these charities and what they do for the
              community is unwavering. We are so thankful to have been able to
              partner with them, as without them our work would not be possible.
              It’s a beautiful thing when people come together, especially
              during times of hardship or need, and it is even more amazing to
              see how charities can help so many others in need. We look forward
              to continuing our relationships with those organizations already
              on board and seeking out new ones further down the line. Thank you
              all again! Let’s work together to create a better future for
              everyone by #MOVEFORCHANGE.
            </p>
            <Link to='/create'>
              <Button classN={styles.create_movement_btn}>
                Create A Movement
              </Button>
            </Link>
          </div>

          {!isMobile && (
            <img
              className={styles.image3}
              src={require('../assets/Images/s3-walking.jpg')}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HomePageSectionThree;
