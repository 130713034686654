import styles from '../styles/form.module.css';
import { useNavigate } from 'react-router-dom';
import FormBtn from '../layouts/FormBtn';
import { FaAngleLeft } from 'react-icons/fa';

const StepsPage = (props) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <section className={styles.section1}>
        <div className={styles.back_btn_container}>
          <FaAngleLeft className={styles.back_btn} onClick={goBack} size={35} />
        </div>
        {/* <img
          src={require('../assets/Images/logo.png')}
          alt='logo'
          className={styles.logo}
        /> */}
        <div className={styles.s1TextBody}>
          <h1 className={styles.step_title_main}>{props.stepTitleMain}</h1>
          <p className={styles.step_title_sub}>{props.stepTitleSub}</p>
          {props.step && (
            <p className={styles.steps_title}>Step {props.stepCount}</p>
          )}
        </div>
      </section>
    </>
  );
};

export default StepsPage;
