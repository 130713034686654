import styles from '../styles/form.module.css';

const FormBtn = (props) => {
  return (
    <>
      <input
        className={props.className ? props.className : styles.continue_btn}
        type={props.type}
        value={props.title}
        onClick={props.onClick}
      />
    </>
  );
};
export default FormBtn;
