import { Link, useNavigate } from 'react-router-dom';

import Footer from '../Footer';
import NavBar from '../Navbar';

import { ReactComponent as Z } from '../../assets/Images/z.svg';
import styles from '../../styles/error.module.css';

const ErrorPage = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <NavBar />
      <div className={styles.body}>
        <Z className={styles.z} />
        <span className={styles.text_body}>
          <span className={styles.title}>We are so Sorry!</span>
          <span className={styles.sub_title}>
            The page you are looking for does not exist anymore
          </span>
        </span>
        <span className={styles.btn_group}>
          <button className={styles.btn_back} onClick={goBack}>
            Back To Previous Page
          </button>
          <Link to='/' className={styles.link}>
            <button className={styles.btn_home}>Return To Home</button>
          </Link>
        </span>
      </div>
      <Footer />
    </>
  );
};

export default ErrorPage;
