import styles from '../styles/form.module.css';

const FormInput = ({
  label,
  register,
  required,
  pattern,
  jsonData,
  type,
  min,
  max,
  value,
  onChange,
  placeholder,
  step,
}) => (
  <div className={styles.formItemDivider}>
    <label className={`${styles.filled}`}>{label}</label>
    <div className={styles.inputContainer}>
      <input
        className={styles.input}
        type={type}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        step={step}
        {...register(jsonData, { required, pattern })}
      />
    </div>
  </div>
);

export default FormInput;
