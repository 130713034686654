import styles from '../styles/form.module.css';

const TextAreaInput = ({
  register,
  required,
  placeholder,
  value,
  onChange,
  values,
}) => {
  return (
    <div className={styles.textbox}>
      {/* <textarea
        // {...register('Text Area', {})}
    /> */}
      <textarea
        className={styles.textareainput}
        placeholder={placeholder}
        rows='6'
        cols='60'
        value={value}
        onChange={onChange}
        {...register('description', {})}
      >
        {values}
      </textarea>
    </div>
  );
};

export default TextAreaInput;
