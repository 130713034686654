const data = {
  clientId: '104629',
  authorizationUrl: 'https://www.strava.com/oauth/authorize',
  tokenUrl: 'https://www.strava.com/oauth/token',
};

const currentOrigin = window.location.origin;

export const stravaLogin = (uid) => {
  const redirectUrl = encodeURIComponent(`${currentOrigin}/exchange/token`); // Update with the appropriate Cloud Function URL
  const localUid = uid; // Replace with the actual local UID
  const stravaAuthUrl = `https://www.strava.com/oauth/authorize?client_id=${data.clientId}&response_type=code&redirect_uri=${redirectUrl}&approval_prompt=force&scope=read_all,activity:read_all&state=${localUid}`;

  // Store the origin URL in localStorage
  localStorage.setItem('originUrl', currentOrigin);

  window.location.assign(stravaAuthUrl);
};
