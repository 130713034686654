import { useEffect, useState, useCallback, useContext } from 'react';
import NavBar from './Navbar';

import styles from '../styles/movementDetails.module.css';
import CustomStripeForm from './donations/CustomStripeForm';
import {
  FaRegUserCircle,
  FaRunning,
  FaRegCopy,
  FaShareAlt,
} from 'react-icons/fa';

import { FaMedal, FaGift } from 'react-icons/fa6';

import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import {
  getMovementInfo,
  stripeCheckout,
  getAmountRaised,
  getMovementDonationByUid,
  getMovementDonationByMovementId,
} from '../config/FirbaseFunctions';
import { formatDate, autoCapitalize } from '../functions/functions';
import loadingSpinner from '../assets/Images/custom-spinner.svg';
import ProgressBar from '@ramonak/react-progress-bar';
import Footer from './Footer';
import { UserContext } from '../components/UserContext';
import DonationItem from '../layouts/DonationItem';

const MovementDetails = (props) => {
  const [details, setDetails] = useState(null);
  const [donations, setTotalDonations] = useState(0);
  const [donationList, setDonationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showStripeForm, setShowStripeForm] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 766 });
  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 990 });
  const isMobileAndTablet = useMediaQuery({ maxWidth: 990 });

  const donationData = [
    {
      amountPaid: 500,
      paymentIntent: 'pi_3NThhYAvGsHXDywP1DBMuGey',
    },
    {
      amountPaid: 500,
      paymentIntent: 'pi_3NThhYAvGsHXDywP1DBMuGey',
    },
    {
      amountPaid: 500,
      paymentIntent: 'pi_3NThhYAvGsHXDywP1DBMuGey',
    },
  ];

  let userStatus = true;

  const context = useContext(UserContext);

  const location = useLocation();
  const navigate = useNavigate();
  const currentURL = location.pathname;
  // const movementLink = `https://dev.moveforchange.ca/${currentURL}`;
  const movementLink = window.location.href;
  // console.log(window.location.href);
  const shareLink = async () => {
    if ('clipboard' in navigator) {
      try {
        await navigator.clipboard.writeText(movementLink);
        console.log('Movement Text have been copied');
      } catch (err) {
        console.error('Failed to copy: ', movementLink);
      }
    }
  };

  const movementInfo = useParams();

  const donate = async () => {
    navigate(`/make/donation/${movementInfo.id}`);
  };

  const makeDonation = async () => {
    setShowStripeForm(!showStripeForm);
    if (context.userID) {
      userStatus = false;
    }

    const payment_details = {
      amount: 10,
      productName: movementInfo.id,
      charity: details.formSubmission.charity || '',
      userId: context.userID || 'anonymous user',
      anonymousUser: userStatus,
    };
    console.log(payment_details);
    setIsLoading(true);
    const data = await stripeCheckout(payment_details).then((res) => {
      alert(res);
    });
  };

  const fetchMovementInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getMovementInfo(movementInfo.id);
      setDetails(data);
      // console.log(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchDonations = useCallback(async () => {
    setIsLoading(true);
    try {
      const movementsData = await getMovementDonationByMovementId(
        movementInfo.id
      );
      const yourDonationsArray = movementsData.map(
        (data) => data.data.donationTotals
      );
      setIsLoading(false);
      setDonationList(yourDonationsArray);
    } catch (error) {
      console.log('Error retrieving movements:', error);
    }
  }, []);
  // console.log(donationList);

  const getTotalRaised = useCallback(async () => {
    setIsLoading(true);
    try {
      const payment = await getAmountRaised(movementInfo.id);

      // console.log(payment);
      if (payment) {
        setTotalDonations(payment);
      }
      if (!payment) {
        setTotalDonations(0);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const formatTotalRaised = (amt) => {
    return Math.round(amt / 100);
  };

  const calculatePecentage = (value, total) => {
    return (value / total) * 100;
  };

  useEffect(() => {
    fetchMovementInfo();
    getTotalRaised();
    fetchDonations();
  }, [fetchDonations]);

  return (
    <>
      <NavBar />
      {!isLoading && details && (
        <div className={styles.details_container}>
          <div className={styles.container}>
            <div className={styles.details_section_one}>
              <h1 className={styles.details_name}>
                {autoCapitalize(details.formSubmission.movementName)}
              </h1>
              <p className={styles.details_desc}>
                {details.formSubmission.description}
              </p>
              <button className={styles.details_btn} onClick={donate}>
                Donate
              </button>

              {isDesktop && (
                <div className={styles.donations}>
                  <div className={styles.donation_title_body}>
                    <FaMedal size={35} color={'F1EC08'} />
                    <span className={styles.donation_title}>
                      Current Donations
                    </span>
                  </div>
                  <div>
                    {donationList.map((donationArray, index) => (
                      <div key={index}>
                        {donationArray.map((donation, innerIndex) => (
                          <DonationItem
                            key={innerIndex}
                            amountPaid={formatTotalRaised(donation.amountPaid)}
                            paymentIntent={donation.paymentIntent}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.details_section_two}>
              {/* create a card component */}
              <div className={styles.details_card}>
                <div className={styles.card_block_one}>
                  {!details.userPhotoUrl && <FaRegUserCircle size={40} />}
                  {details.userPhotoUrl && (
                    <img
                      src={details.userPhotoUrl}
                      alt='Avatar'
                      className={styles.avatar}
                    />
                  )}
                  {/* <span className={styles.inner_card_one}> */}
                  <p className={styles.card_title}>
                    {details.formSubmission.charity}
                  </p>
                  {/* <p>Charity</p> */}
                  {/* </span> */}
                </div>
                <div className={styles.card_block_two}>
                  <p>Click the button below to donate</p>
                  <button className={styles.card_btn} onClick={donate}>
                    Donate
                  </button>
                </div>

                <div className={styles.card_block_three}>
                  <div className={styles.block}>
                    <div className={styles.goal}>
                      <span className={styles.goal_title}>Goal</span>
                      <span className={styles.goal_subtitle}>
                        ${details.formSubmission.money}
                      </span>
                    </div>
                    <div className={styles.vl}></div>
                    <div className={styles.goal}>
                      <span className={styles.goal_title}>Raised</span>
                      <span className={styles.goal_subtitle}>
                        ${donations && formatTotalRaised(donations.raised)}
                      </span>
                    </div>
                  </div>
                  <div className={styles.progress}>
                    <FaRunning />
                    <ProgressBar
                      completed={
                        donations
                          ? calculatePecentage(
                              formatTotalRaised(donations.raised),
                              details.formSubmission.money
                            )
                          : 0
                      }
                      className={styles.progress_bar}
                      bgColor='#7DF108'
                      borderRadius='0px'
                      isLabelVisible={false}
                    />
                  </div>
                  <div className={styles.block}>
                    <div className={styles.goal}>
                      <span className={styles.goal_title}>Start Date</span>
                      <span className={styles.goal_subtitle}>
                        {formatDate(details.formSubmission.startDate)}
                      </span>
                    </div>
                    <div className={styles.vl}></div>
                    <div className={styles.goal}>
                      <span className={styles.goal_title}>End Date</span>
                      <span className={styles.goal_subtitle}>
                        {formatDate(details.formSubmission.endDate)}
                      </span>
                    </div>
                  </div>
                  <div className={styles.copy}>
                    <span>Copy link to share with friends</span>
                    <FaShareAlt
                      className={styles.copy_icon}
                      onClick={shareLink}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isMobileAndTablet && (
              <div className={styles.donations}>
                {donationList.map((donationArray, index) => (
                  <div key={index}>
                    {donationArray.map((donation, innerIndex) => (
                      <DonationItem
                        key={innerIndex}
                        amountPaid={formatTotalRaised(donation.amountPaid)}
                        paymentIntent={donation.paymentIntent}
                      />
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {isLoading && (
        <div className={styles.details_container}>
          <div className={styles.container}>
            <img
              src={loadingSpinner}
              alt='loading'
              className={styles.loading}
            />
          </div>
        </div>
      )}
      {!isLoading && <Footer />}
    </>
  );
};

export default MovementDetails;
