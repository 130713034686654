const navbarItems = [
  {
    title: 'Home',
    url: '/',
    class: 'list_item',
  },
  {
    title: 'Movements',
    url: '/movements',
    class: 'list_item',
  },
  {
    title: 'Charities',
    url: '/charities',
    class: 'list_item',
  },
  // {
  //   title: 'Donations',
  //   url: '/donations',
  //   class: 'list_item',
  // },
  {
    title: 'About Us',
    url: '/about',
    class: 'list_item',
  },
];
export default navbarItems;
