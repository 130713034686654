//pages
import NavBar from '../pages/Navbar';
import HomePageSectionOne from '../pages/HPSectionOne';
import HomePageSectionTwo from '../pages/HPSectionTwo';
import HomePageSectionThree from '../pages/HPSectionThree';
import styles from '../styles/homepage.module.css';
import HomePageSectionFour from '../pages/HPSectionFour';
import Footer from '../pages/Footer';

const HomeComponent = (props) => {
  return (
    <>
      <NavBar />
      <div className={styles.hp_main}>
        <HomePageSectionOne />
        {/* <HomePageSectionTwo /> */}
        <HomePageSectionThree />
        {/* <HomePageSectionFour /> */}
      </div>
      <Footer />
    </>
  );
};

export default HomeComponent;
