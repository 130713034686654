export const tips = [
  {
    key: 0,
    name: '0%',
    value: 0,
  },

  {
    key: 3,
    name: '3%',
    value: 3,
  },
  {
    key: 4,
    name: '4%',
    value: 4,
  },
  {
    key: 5,
    name: '5%',
    value: 5,
  },
  {
    key: 10,
    name: '10%',
    value: 10,
  },
  {
    key: 15,
    name: '15%',
    value: 15,
  },
  {
    key: 20,
    name: '20%',
    value: 20,
  },
  {
    key: 25,
    name: '25%',
    value: 25,
  },
];
