import styles from '../styles/footer.module.css';

import { FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

const Footer = () => {
  const isMobile = useMediaQuery({ maxWidth: 766 });
  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 990 });
  const isDesktop = useMediaQuery({ minWidth: 991 });
  return (
    <>
      <div className={styles.footer}>
        <div class={styles.footer_top}></div>
        <div className={styles.container}>
          <div className={styles.footer_body}>
            {!isTablet && !isDesktop && (
              <img
                src={require('../assets/Images/logo.png')}
                className={styles.logo}
                alt='move for change logo'
              />
            )}
            <span className={styles.follow}>
              Follow Us <FaFacebookSquare size={20} />
              <FaInstagramSquare size={20} />
            </span>
            <span className={styles.copyright}>
              Copyright © 2023. All Rights Reserved by MOVE FOR CHANGE
            </span>
            <span className={styles.privacy}>
              Privacy Policy | Terms and Condition
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
