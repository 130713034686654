import { useState } from 'react';
import { UserContext } from './components/UserContext';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebaseConfig';
import Router from './routes/router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  'pk_live_51H2UBNIZGnx7Z30tjth4XgtYpYN1R8C6HCRhrpBfInuc88uJD0dGB5LqOEHWpvrFvGe6IAmMj3dFwniIU64mkREc004qrVRVvB'
);

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userID, setUserID] = useState('');
  const [stravaID, setStravaID] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [displayName, setDisplayName] = useState('');

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setLoggedIn(true);
      setUserID(user.uid);
      setPhotoUrl(user.photoURL);
      setDisplayName(user.displayName);
      // console.log(JSON.stringify(user) + ' <= this is the user object');

      // console.log(
      //   // JSON.stringify(user) +
      //   'user is logged info => ' +
      //     user.uid +
      //     ' ' +
      //     user.displayName +
      //     ' ' +
      //     user.photoURL
      // );
    } else {
      setLoggedIn(false);
      console.log('user is not logged in');
    }
  });

  return (
    <Elements stripe={stripePromise}>
      <UserContext.Provider
        value={{
          isLoggedIn: isLoggedIn,
          userID: userID,
          photoUrl: photoUrl,
          displayName: displayName,
        }}
      >
        <Router />
      </UserContext.Provider>
    </Elements>
  );
}

export default App;
