import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  getDocs,
  collection,
  query,
  where,
  addDoc,
} from '@firebase/firestore';
import {
  GoogleAuthProvider,
  getAuth,
  signOut,
  signInWithPopup,
} from '@firebase/auth';

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
// };

const firebaseConfig = {
  apiKey: 'AIzaSyAfgZFd6cJ5mQ_oWsGuHGyoddqY8i1OlV4',
  authDomain: 'move-for-change.firebaseapp.com',
  databaseURL: 'https://move-for-change.firebaseio.com',
  projectId: 'move-for-change',
  storageBucket: 'move-for-change.appspot.com',
  messagingSenderId: '695498805049',
  appId: '1:695498805049:web:2214fb994c10d35df3a2e8',
  measurementId: 'G-P990W4ZLSC',
};
const app = initializeApp(firebaseConfig);

//exports
export const firestore = getFirestore(app);
export const auth = getAuth(app);

// Functions

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(
      collection(firestore, 'MFC_Users'),
      where('uid', '==', user.uid)
    );
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(firestore, 'MFC_Users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    console.log(err.message);
  }
};

const logout = () => {
  signOut(auth);
};
export {
  query,
  collection,
  where,
  getDocs,
  addDoc,
  signOut,
  signInWithPopup,
  signInWithGoogle,
  logout,
};
